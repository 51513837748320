import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import SectionHeading from "../SectionHeading/SectionHeading";

const Resources = () => {
  const [isActive, setIsActive] = useState(0);

  return (
    <section id="resources">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading
        title="Resources"
        subTitle="
        Unlock these powerful resources and take your clinical laboratory career <br/> to the next level with a Skilled Lab Membership"
      />
      <div className="container">
        <div className="st-tabs st-fade-tabs st-style1">
          <ul className="st-tab-links st-style1 st-mp0">
            <li
              className={`st-tab-title ${isActive === 0 ? "active" : ""}`}
              onClick={() => setIsActive(0)}
            >
              <span className="st-blue-box">
                <svg
                  width="800px"
                  height="800px"
                  viewBox="0 0 800 800"
                  enable-background="new 0 0 800 800"
                  id="GUIDE"
                  version="1.1"
                  xmlSpace="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsCc="http://creativecommons.org/ns#"
                  xmlnsDc="http://purl.org/dc/elements/1.1/"
                  xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape"
                  xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                  xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g>
                    <path d="M676.637,183.386c0.002-0.002,0.004-0.004,0.005-0.005L522.549,29.287c-3.619-3.62-8.62-5.86-14.145-5.86H137.5   c-11.046,0-20,8.954-20,20v713.146c0,11.046,8.954,20,20,20h525c11.046,0,20-8.954,20-20V197.522   C682.5,192.407,680.426,187.203,676.637,183.386z M642.5,736.573h-485V63.427h342.62l114.096,114.095l-85.812,0v-41.788   c0-11.046-8.954-20-20-20s-20,8.954-20,20v61.788c0,11.046,8.954,20,20,20c0,0,92.404,0,134.096,0V736.573z" />

                    <path d="M295.217,224.417l-39.854,39.855l-5.697-5.697c-7.811-7.811-20.473-7.811-28.283,0c-7.811,7.81-7.811,20.473,0,28.284   l19.84,19.84c3.75,3.751,8.838,5.858,14.142,5.858c5.305,0,10.392-2.107,14.143-5.858l53.996-53.999   c7.81-7.811,7.81-20.474-0.001-28.284C315.69,216.606,303.027,216.606,295.217,224.417z" />

                    <path d="M557.831,312.557h6.646c11.046,0,20-8.954,20-20s-8.954-20-20-20h-6.646c-11.046,0-20,8.954-20,20   S546.785,312.557,557.831,312.557z" />

                    <path d="M367.389,272.557c-11.046,0-20,8.954-20,20s8.954,20,20,20h129.609c11.046,0,20-8.954,20-20s-8.954-20-20-20H367.389z" />

                    <path d="M557.831,435.552h6.646c11.046,0,20-8.954,20-20s-8.954-20-20-20h-6.646c-11.046,0-20,8.954-20,20   S546.785,435.552,557.831,435.552z" />

                    <path d="M496.998,395.552H367.389c-11.046,0-20,8.954-20,20s8.954,20,20,20h129.609c11.046,0,20-8.954,20-20   S508.044,395.552,496.998,395.552z" />

                    <path d="M557.831,558.547h6.646c11.046,0,20-8.954,20-20s-8.954-20-20-20h-6.646c-11.046,0-20,8.954-20,20   S546.785,558.547,557.831,558.547z" />

                    <path d="M496.998,518.547H367.389c-11.046,0-20,8.954-20,20s8.954,20,20,20h129.609c11.046,0,20-8.954,20-20   S508.044,518.547,496.998,518.547z" />

                    <path d="M557.831,681.542h6.646c11.046,0,20-8.954,20-20s-8.954-20-20-20h-6.646c-11.046,0-20,8.954-20,20   S546.785,681.542,557.831,681.542z" />

                    <path d="M496.998,641.542H367.389c-11.046,0-20,8.954-20,20s8.954,20,20,20h129.609c11.046,0,20-8.954,20-20   S508.044,641.542,496.998,641.542z" />

                    <path d="M255.363,435.552c5.304,0,10.392-2.107,14.142-5.858l53.996-53.996c7.811-7.811,7.811-20.475,0-28.285   s-20.473-7.811-28.283,0l-39.854,39.855l-5.697-5.698c-7.81-7.81-20.474-7.812-28.284-0.001s-7.811,20.474-0.001,28.284   l19.84,19.841C244.972,433.444,250.059,435.552,255.363,435.552z" />

                    <path d="M234.239,511.547l-12.856,12.857c-7.81,7.811-7.81,20.474,0.001,28.284c3.905,3.905,9.023,5.857,14.142,5.857   s10.237-1.952,14.143-5.858l12.855-12.855l12.856,12.855c3.904,3.906,9.023,5.858,14.142,5.858s10.237-1.952,14.142-5.858   c7.811-7.811,7.811-20.473,0-28.283l-12.855-12.857l12.856-12.857c7.81-7.811,7.81-20.474-0.001-28.284   c-7.811-7.81-20.474-7.81-28.284,0.001l-12.856,12.856l-12.857-12.856c-7.811-7.811-20.473-7.811-28.283,0s-7.811,20.474,0,28.283   L234.239,511.547z" />

                    <path d="M295.217,593.4l-39.854,39.855l-5.697-5.697c-7.811-7.811-20.473-7.811-28.283,0c-7.811,7.81-7.811,20.473,0,28.283   l19.84,19.84c3.75,3.752,8.838,5.858,14.142,5.858c5.305,0,10.392-2.107,14.143-5.858l53.996-53.998   c7.81-7.811,7.81-20.474-0.001-28.284C315.69,585.59,303.027,585.59,295.217,593.4z" />
                  </g>
                </svg>
                <span>Past Exams</span>
              </span>
            </li>
            <li
              className={`st-tab-title ${isActive === 1 ? "active" : ""}`}
              onClick={() => setIsActive(1)}
            >
              <span className="st-dip-blue-box">
                <svg
                  fill="#000000"
                  height="800px"
                  width="800px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M84.718,340.769c4.461,0,8.075-3.614,8.075-8.075V179.307c0-4.461-3.614-8.075-8.075-8.075s-8.075,3.614-8.075,8.075
				v153.388C76.643,337.154,80.257,340.769,84.718,340.769z"
                        />
                        <path
                          d="M428.411,370.76c-17.059,0-30.938,13.88-30.938,30.94c0,17.059,13.88,30.937,30.938,30.937
				c17.061,0,30.94-13.88,30.94-30.937C459.351,384.638,445.472,370.76,428.411,370.76z M428.411,416.488
				c-8.154,0-14.787-6.633-14.787-14.787c0-8.155,6.633-14.789,14.787-14.789c8.155,0,14.789,6.635,14.789,14.789
				C443.201,409.853,436.566,416.488,428.411,416.488z"
                        />
                        <path
                          d="M510.804,435.5L408.597,53.357c-4.101-15.344-18.079-26.059-33.989-26.059c-3.043,0-6.084,0.401-9.039,1.192
				l-69.095,18.479c-9.04,2.417-16.611,8.233-21.318,16.38c-4.708,8.144-5.969,17.608-3.552,26.647l10.143,37.927
				c-3.439-1.127-7.108-1.745-10.921-1.745h-82.404c-6.993,0-13.51,2.062-18.992,5.597V48.945c0-19.377-15.764-35.142-35.14-35.142
				H35.14C15.765,13.805,0,29.568,0,48.945v414.109c0,19.377,15.765,35.142,35.142,35.142h99.152
				c10.877,0,20.614-4.968,27.065-12.754c6.451,7.786,16.189,12.754,27.067,12.754h82.404c19.377,0,35.142-15.765,35.142-35.142
				V218.482l67.841,253.655c4.102,15.343,18.081,26.058,33.99,26.058c3.043,0,6.084-0.401,9.039-1.191l69.096-18.477
				C504.652,473.519,515.807,454.218,510.804,435.5z M153.282,463.056c0,10.472-8.519,18.992-18.99,18.992H35.141
				c-10.471-0.001-18.991-8.521-18.991-18.992v-17.647h137.132V463.056z M153.282,429.258H16.15v-31.285h137.132V429.258z
				 M153.282,381.823H16.15V130.178h137.132V381.823z M153.282,114.028H16.15V82.743h137.132V114.028z M153.283,66.592h-0.001H16.15
				V48.945c0-10.472,8.52-18.992,18.992-18.992h99.152c10.471,0,18.99,8.52,18.99,18.992V66.592z M289.82,463.056
				c0,10.472-8.52,18.992-18.992,18.992h-82.404c-10.472,0-18.992-8.52-18.992-18.992V161.317c0-10.472,8.52-18.991,18.992-18.991
				h82.404c10.472,0,18.992,8.519,18.992,18.991V463.056z M287.207,85.822c-1.303-4.871-0.616-9.983,1.933-14.393
				c2.55-4.41,6.637-7.556,11.509-8.859l69.096-18.479c1.592-0.426,3.23-0.643,4.866-0.643c8.609,0,16.17,5.789,18.387,14.08
				l11.308,42.281L298.515,128.1L287.207,85.822z M302.687,143.701l105.79-28.291l6.93,25.909l-105.79,28.291L302.687,143.701z
				 M313.789,185.213l105.791-28.291l3.477,13l-105.791,28.291L313.789,185.213z M321.439,213.814l105.79-28.291l6.931,25.908
				l-105.791,28.291L321.439,213.814z M481.761,462.925l-69.096,18.477c-1.592,0.426-3.23,0.643-4.866,0.643
				c-8.609,0-16.172-5.79-18.389-14.08l-56.872-212.641l105.791-28.291l56.872,212.638
				C497.906,449.786,491.877,460.22,481.761,462.925z"
                        />
                        <path
                          d="M452.396,341.315c-1.152-4.309-5.587-6.869-9.887-5.715l-59.372,15.877c-4.309,1.151-6.867,5.578-5.715,9.887
				c0.966,3.61,4.229,5.991,7.795,5.991c0.691,0,1.393-0.089,2.091-0.277l59.372-15.877
				C450.99,350.05,453.548,345.624,452.396,341.315z"
                        />
                        <path
                          d="M263.909,308.725h-68.565c-4.461,0-8.075,3.614-8.075,8.075c0,4.46,3.614,8.075,8.075,8.075h68.565
				c4.461,0,8.075-3.616,8.075-8.075C271.984,312.339,268.37,308.725,263.909,308.725z"
                        />
                        <path
                          d="M229.626,203.676c-25.036,0-45.404,20.369-45.404,45.405c0,25.036,20.368,45.404,45.404,45.404
				c25.037,0,45.406-20.368,45.406-45.404C275.031,224.044,254.663,203.676,229.626,203.676z M229.626,278.334
				c-16.13,0-29.254-13.123-29.254-29.253c0-16.131,13.124-29.255,29.254-29.255c16.131,0,29.256,13.123,29.256,29.255
				C258.881,265.212,245.758,278.334,229.626,278.334z"
                        />
                        <path
                          d="M263.909,339.804h-68.565c-4.461,0-8.075,3.614-8.075,8.075s3.614,8.075,8.075,8.075h68.565
				c4.461,0,8.075-3.614,8.075-8.075S268.37,339.804,263.909,339.804z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Study Guides</span>
              </span>
            </li>
            <li
              className={`st-tab-title ${isActive === 2 ? "active" : ""}`}
              onClick={() => setIsActive(2)}
            >
              <span className="st-green-box">
                <svg
                  fill="#000000"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="800px"
                  height="800px"
                  viewBox="0 0 44.535 44.536"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M38.295,9.503c0-1.751-1.425-3.175-3.176-3.175s-3.176,1.424-3.176,3.175s1.425,3.176,3.176,3.176
			S38.295,11.254,38.295,9.503z M35.12,10.679c-0.647,0-1.176-0.527-1.176-1.176c0-0.647,0.526-1.175,1.176-1.175
			s1.176,0.527,1.176,1.175C36.295,10.152,35.768,10.679,35.12,10.679z"
                      />
                      <path
                        d="M6.285,30.8c0,1.751,1.424,3.176,3.175,3.176s3.176-1.425,3.176-3.176c0-1.75-1.425-3.175-3.176-3.175
			S6.285,29.05,6.285,30.8z M10.635,30.8c0,0.647-0.527,1.176-1.176,1.176c-0.647,0-1.175-0.526-1.175-1.176
			c0-0.647,0.527-1.175,1.175-1.175C10.108,29.625,10.635,30.153,10.635,30.8z"
                      />
                      <path d="M33.832,14.542v7.098c0,0.553,0.447,1,1,1s1-0.447,1-1v-7.098c0-0.553-0.447-1-1-1S33.832,13.99,33.832,14.542z" />
                      <path
                        d="M37.982,26.077c0.252,0,0.478-0.103,0.652-0.257c0.051,0.009,0.098,0.029,0.148,0.029c0.256,0,0.512-0.099,0.707-0.293
			l4.752-4.752c0.391-0.391,0.391-1.023,0-1.414l-5.553-5.554c-0.092-0.093-0.202-0.166-0.325-0.217
			c-0.244-0.102-0.521-0.102-0.765,0s-0.438,0.296-0.541,0.541c-0.051,0.122-0.076,0.252-0.076,0.382v10.533
			C36.982,25.627,37.43,26.077,37.982,26.077z M38.982,23.236v-6.278l3.14,3.14L38.982,23.236z"
                      />
                      <path
                        d="M31.278,26.077c0.553,0,1-0.447,1-1V14.542c0-0.005-0.003-0.009-0.003-0.014c-0.002-0.086-0.027-0.167-0.051-0.248
			c-0.013-0.045-0.013-0.094-0.029-0.135c-0.024-0.056-0.068-0.1-0.102-0.15c-0.039-0.056-0.066-0.117-0.115-0.165
			c-0.03-0.03-0.074-0.047-0.109-0.074c-0.068-0.052-0.137-0.105-0.217-0.139c-0.006-0.002-0.008-0.006-0.014-0.008l-8.463-3.282
			c-0.512-0.199-1.094,0.056-1.293,0.571c-0.199,0.515,0.056,1.094,0.571,1.294l7.824,3.034v9.849
			C30.278,25.627,30.725,26.077,31.278,26.077z"
                      />
                      <path
                        d="M27.795,19.822H6.81V4.906c0-0.553-0.447-1-1-1c-0.553,0-1,0.447-1,1v15.916c0,0.553,0.447,1,1,1h21.985
			c0.553,0,1-0.447,1-1C28.795,20.269,28.348,19.822,27.795,19.822z"
                      />
                      <path d="M0,2.014c0,0.553,0.447,1,1,1h36.295c0.553,0,1-0.447,1-1c0-0.553-0.447-1-1-1H1C0.448,1.014,0,1.461,0,2.014z" />
                      <path
                        d="M10.074,16.04v-4.78c0-0.553-0.447-1-1-1c-0.553,0-1,0.447-1,1v4.779c0,0.553,0.447,1,1,1
			C9.627,17.039,10.074,16.592,10.074,16.04z"
                      />
                      <path
                        d="M13.508,16.04v-7.7c0-0.553-0.447-1-1-1c-0.553,0-1,0.447-1,1v7.699c0,0.553,0.447,1,1,1
			C13.061,17.039,13.508,16.592,13.508,16.04z"
                      />
                      <path
                        d="M17.802,16.04v-3.85c0-0.553-0.447-1-1-1c-0.553,0-1,0.447-1,1v3.85c0,0.553,0.447,1,1,1
			C17.355,17.04,17.802,16.592,17.802,16.04z"
                      />
                      <path
                        d="M5.424,43.521h8.072c0.031,0,0.057-0.015,0.087-0.019h7.985h8.075c0.553,0,1-0.446,1-1v-5.84c0-0.554-0.447-1-1-1h-8.075
			h-8.072c-0.031,0-0.057,0.015-0.087,0.018H5.424c-0.553,0-1,0.447-1,1v5.841C4.424,43.075,4.871,43.521,5.424,43.521z
			 M28.643,41.502h-6.073v-3.84h6.073V41.502z M20.569,41.502h-6.072v-3.84h6.072V41.502z M6.424,37.68h6.072v3.841H6.424V37.68z"
                      />
                      <path
                        d="M14.358,30.784c0,1.75,1.424,3.176,3.175,3.176s3.175-1.426,3.175-3.176s-1.424-3.175-3.175-3.175
			S14.358,29.034,14.358,30.784z M18.707,30.784c0,0.647-0.527,1.176-1.175,1.176s-1.175-0.527-1.175-1.176
			c0-0.647,0.527-1.175,1.175-1.175S18.707,30.135,18.707,30.784z"
                      />
                      <path
                        d="M25.605,27.609c-1.75,0-3.175,1.425-3.175,3.175s1.425,3.176,3.175,3.176c1.752,0,3.177-1.426,3.177-3.176
			S27.357,27.609,25.605,27.609z M25.605,31.958c-0.647,0-1.176-0.526-1.176-1.176c0-0.646,0.527-1.174,1.176-1.174
			c0.649,0,1.177,0.526,1.177,1.174C26.782,31.431,26.254,31.958,25.605,31.958z"
                      />
                    </g>
                  </g>
                </svg>
                <span>Tutoring</span>
              </span>
            </li>
            <li
              className={`st-tab-title ${isActive === 3 ? "active" : ""}`}
              onClick={() => setIsActive(3)}
            >
              <span className="st-gold-box">
                <svg
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23 1v18h-3v-1h2V2H2v16h8v1H1V1zm-7 2H8v1h8zm-2 3V5h-4v1zm-7 5H3v1h4zm0 2H3v1h4zm-4 3h2v-1H3zm14-3a2 2 0 1 1-2-2 2.002 2.002 0 0 1 2 2zm-1 0a1 1 0 1 0-1 1 1.001 1.001 0 0 0 1-1zm.002-4.293a.965.965 0 0 0 1.32.55 1.08 1.08 0 0 1 1.213.207 1.066 1.066 0 0 1 .21 1.21.966.966 0 0 0 .548 1.324 1.064 1.064 0 0 1 0 2.004.965.965 0 0 0-.549 1.323A1.05 1.05 0 0 1 18 16.816v7.046l-3-2.538-3 2.538v-7.046a1.05 1.05 0 0 1-.744-1.49.965.965 0 0 0-.549-1.324 1.064 1.064 0 0 1 0-2.004.966.966 0 0 0 .549-1.324 1.066 1.066 0 0 1 .209-1.21 1.08 1.08 0 0 1 1.212-.206.965.965 0 0 0 1.32-.551 1.064 1.064 0 0 1 2.005 0zm.998 13v-5.04a.93.93 0 0 0-.998.625 1.064 1.064 0 0 1-2.004 0 .93.93 0 0 0-.998-.625v5.039l2-1.692zm-1.94-4.749a1.967 1.967 0 0 1 1.853-1.308 2.12 2.12 0 0 1 .87.197l.058-.091a1.964 1.964 0 0 1 1.116-2.695v-.122a1.966 1.966 0 0 1-1.116-2.695l-.087-.084a1.965 1.965 0 0 1-2.694-1.117h-.12a1.965 1.965 0 0 1-2.694 1.117l-.087.084a1.966 1.966 0 0 1-1.116 2.695v.122a1.964 1.964 0 0 1 1.116 2.695l.058.09a2.12 2.12 0 0 1 .87-.196 1.967 1.967 0 0 1 1.853 1.308L15 17z" />
                  <path fill="none" d="M0 0h24v24H0z" />
                </svg>
                <span>Short courses</span>
              </span>
            </li>
            {/* <li
              className={`st-tab-title ${isActive === 4 ? "active" : ""}`}
              onClick={() => setIsActive(4)}
            >
              <span className="st-orange-box">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 480.335 480.335"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M341.311,0c-6.249,0.011-12.488,0.5-18.662,1.462c-14.488,2.469-28.449,7.393-41.28,14.56
                  c-25.694,13.956-56.706,13.956-82.4,0c-12.835-7.142-26.796-12.039-41.28-14.48C90.764-8.885,28.058,36.915,17.63,103.84
                  c-0.958,6.148-1.447,12.36-1.462,18.582v13.6c-0.142,22.285,5.925,44.169,17.52,63.2c30.684,49.633,50.202,105.349,57.2,163.28
                  l8.88,73.52c2.439,22.858,20.241,41.057,43.04,44c26.339,3.001,50.124-15.918,53.125-42.258c0.027-0.234,0.052-0.468,0.075-0.702
                  l16.72-156.16c1.639-15.155,15.253-26.111,30.408-24.472c12.894,1.394,23.078,11.578,24.472,24.472l16.72,155.92
                  c2.474,24.619,23.257,43.324,48,43.2h1.36c23.909,0.276,44.375-17.086,48-40.72l12.4-77.84c9.56-57.745,28.475-113.545,56-165.2
                  c9.255-17.588,14.087-37.166,14.08-57.04v-16.8C464.048,54.69,409.043-0.12,341.311,0z M448.169,139.222
                  c-0.046,17.675-4.442,35.066-12.8,50.64c-28.409,52.741-47.746,109.885-57.2,169.04l-12.48,77.92
                  c-2.393,15.777-16.043,27.38-32,27.2h-1.36c-16.523,0.079-30.389-12.435-32-28.88l-16.72-155.12
                  c-1.998-23.997-23.071-41.83-47.068-39.831c-20.83,1.735-37.499,18.008-39.732,38.791l-16.64,155.28
                  c-1.287,14.018-11.354,25.662-25.04,28.96c-17.185,4.123-34.459-6.466-38.582-23.652c-0.318-1.326-0.551-2.672-0.698-4.028
                  l-10.24-85.2c-7.763-56.732-27.566-111.148-58.08-159.6c-9.991-16.508-15.3-35.424-15.36-54.72v-13.6
                  C32.174,63.659,79.816,16.027,138.579,16.032c18.05,0.002,35.804,4.596,51.589,13.349c31.071,17.367,68.929,17.367,100,0v0.08
                  c51.39-28.498,116.153-9.94,144.651,41.45c8.741,15.762,13.334,33.486,13.349,51.51V139.222z"
                    />
                    <path
                      d="M308.089,65.062c-21.091,10.424-44.401,15.559-67.92,14.96c-23.572,0.625-46.941-4.51-68.08-14.96
                  c-3.845-2.158-8.711-0.798-10.88,3.04c-2.158,3.845-0.798,8.711,3.04,10.88c23.519,11.841,49.597,17.694,75.92,17.04
                  c26.323,0.654,52.401-5.199,75.92-17.04c3.844-2.209,5.169-7.116,2.96-10.96C316.84,64.178,311.933,62.853,308.089,65.062z"
                    />
                  </g>
                </svg>
                <span>Dental care</span>
              </span>
            </li> */}
            {/* <li
              className={`st-tab-title ${isActive === 5 ? "active" : ""}`}
              onClick={() => setIsActive(5)}
            >
              <span className="st-gray-box">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.001 512.001"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M509.61,192.336c-0.918-2.538-2.156-5.031-3.782-7.615c-0.037-0.057-0.072-0.113-0.11-0.17l-1.203-1.81
                c-0.031-0.046-0.064-0.094-0.096-0.14c-0.41-0.589-0.831-1.16-1.268-1.718c-0.909-1.16-1.873-2.248-2.864-3.232l-2.618-2.6
                l2.404-2.801c1.611-1.879,2.956-4.125,4.356-7.286c0.023-0.052,0.045-0.103,0.068-0.156c0.609-1.455,1.184-2.876,1.516-4.22
                c0.4-1.575,0.716-3.175,0.956-4.841c0.983-6.48,0.127-13.603-2.501-20.671c-0.646-1.687-1.481-3.31-2.281-4.828
                c-0.028-0.053-0.056-0.105-0.085-0.157l-0.236-0.425c-0.789-1.423-1.535-2.767-2.348-3.82l-0.08-0.105
                c-1.046-1.401-2.17-2.767-3.312-4.029c-1.257-1.403-2.32-2.423-3.395-3.266c-3.167-2.524-5.427-3.985-7.731-5.011
                c-2.726-1.246-5.483-2.147-8.191-2.679l-3.58-0.704l0.403-3.627c0.305-2.749,0.274-5.672-0.084-8.618
                c-0.284-2.514-0.959-5.094-2.261-8.626c-0.021-0.056-0.041-0.111-0.063-0.166c-2.31-5.869-6.193-11.361-11.164-15.825
                c-2.675-2.453-5.706-4.317-8.049-5.667c-0.055-0.031-0.111-0.064-0.167-0.094c-2.427-1.324-5.16-2.405-8.593-3.404
                c-2.402-0.7-5.133-1.097-8.595-1.251c-0.045-0.002-0.091-0.003-0.136-0.004c-0.439-0.012-0.874-0.019-1.307-0.019
                c-0.693,0-1.718,0.018-2.759,0.114c-1.409,0.147-2.807,0.369-4.155,0.661l-3.838,0.832l-0.871-3.83
                c-2.52-11.089-11.136-21.184-22.479-26.344c-5.557-2.53-11.384-3.814-17.318-3.814c-6.059,0-11.954,1.383-17.049,3.999
                l-3.515,1.804l-1.819-3.509c-5.336-10.289-15.625-17.866-27.498-20.266c-2.873-0.59-5.795-0.889-8.685-0.889
                c-9.16,0-17.872,2.979-24.531,8.388l-3.127,2.54l-2.492-3.166c-1.678-2.132-3.652-4.089-5.83-5.787
                c-2.164-1.708-4.553-3.233-7.1-4.528c-0.092-0.046-0.184-0.092-0.279-0.135c-5.742-2.649-11.578-4.009-17.345-4.04
                c-0.231-0.004-0.994-0.018-1.267-0.018c-5.415,0-10.942,1.173-16.426,3.486c-0.095,0.04-0.189,0.082-0.282,0.125
                c-2.584,1.209-5.037,2.665-7.257,4.301c-2.239,1.633-4.258,3.509-6.001,5.577l-2.596,3.081l-3.042-2.644
                c-6.867-5.972-16.002-9.26-25.722-9.26c-2.385,0-4.802,0.203-7.181,0.603c-11.937,2.003-22.471,9.187-28.178,19.216l-1.95,3.424
                l-3.437-1.925c-5.511-3.087-11.947-4.719-18.614-4.719c-5.298,0-10.527,1.021-15.541,3.032c-0.048,0.019-0.098,0.04-0.147,0.06
                c-3.069,1.302-5.839,2.787-8.231,4.416c-0.06,0.041-0.119,0.082-0.178,0.125c-2.441,1.759-4.691,3.721-6.686,5.831
                c-0.064,0.067-0.125,0.134-0.185,0.203c-4.047,4.582-6.823,9.5-8.249,14.617l-1.05,3.768l-3.781-0.996
                c-2.906-0.765-5.665-1.184-8.203-1.241c-0.038-0.001-0.074-0.001-0.111-0.001l-0.601-0.005c-2.628,0-5.332,0.259-8.037,0.77
                c-0.063,0.012-0.124,0.024-0.186,0.038c-2.725,0.581-5.549,1.527-8.699,2.921c-2.503,1.133-5.216,2.8-8.298,5.096
                c-0.055,0.041-0.11,0.083-0.164,0.126c-2.267,1.782-4.511,4.002-6.862,6.791c-0.059,0.07-0.117,0.142-0.175,0.214
                c-1.765,2.244-3.398,4.854-4.855,7.76c-0.044,0.087-0.086,0.176-0.127,0.264c-2.547,5.572-3.741,11.38-3.454,16.797l0.203,3.81
                l-3.798,0.35c-1.325,0.122-2.685,0.324-4.042,0.603c-0.095,0.019-0.19,0.041-0.284,0.064c-1.186,0.287-2.483,0.69-3.906,1.215
                c-3.243,1.22-5.859,2.574-7.95,4.104l-0.109,0.084c-3.085,2.169-5.393,4.229-7.203,6.411l-0.115,0.136
                c-1.076,1.241-2.119,2.559-3.101,3.914c-0.089,0.124-0.176,0.25-0.259,0.379c-0.848,1.324-1.696,2.806-2.573,4.499
                c-3.138,5.994-4.79,13.099-4.651,20.048c0.071,2.805,0.563,5.908,1.462,9.225c0.019,0.071,0.04,0.142,0.06,0.212
                c0.76,2.527,1.97,5.144,3.6,7.78l1.882,3.044l-2.836,2.184c-7.476,5.757-15.259,18.566-12.593,35.716
                c0.023,0.141,0.047,0.282,0.078,0.423c0.402,1.909,0.894,3.824,1.487,5.774l0.182,0.576c0.314,1.005,0.46,1.459,0.7,2.026
                c0.414,0.953,0.847,1.888,1.273,2.752c0.82,1.633,1.706,3.357,2.721,4.889c0.06,0.091,0.122,0.18,0.185,0.268
                c1.081,1.499,2.25,2.966,3.433,4.303l0.312,0.345c0.518,0.577,0.916,1.013,1.316,1.396c0.077,0.073,0.154,0.144,0.234,0.214
                c0.654,0.579,1.315,1.132,1.975,1.656c1.271,1.01,2.578,1.937,3.885,2.756c0.155,0.097,0.314,0.19,0.474,0.276
                c2.702,1.45,5.496,2.496,8.302,3.113l3.146,0.692l-0.031,3.222c-0.029,3.008,0.36,6.078,1.125,8.878
                c0.028,0.105,0.059,0.208,0.092,0.312c0.47,1.481,1.046,2.991,1.708,4.479c0.314,0.706,0.648,1.414,1.006,2.121
                c0.069,0.136,0.141,0.27,0.218,0.401c0.375,0.649,0.774,1.282,1.191,1.926c0.939,1.439,1.979,2.875,3.094,4.268
                c0.069,0.087,0.141,0.172,0.213,0.255c0.955,1.097,2.077,2.236,3.676,3.722c0.698,0.641,1.416,1.274,2.123,1.866
                c0.592,0.484,1.125,0.85,1.554,1.144l0.591,0.409c1.489,1.015,3.055,1.985,4.655,2.882c0.119,0.067,0.239,0.13,0.361,0.191
                c6.095,3.023,12.422,4.622,18.295,4.622c0.341,0,0.678-0.004,1.103-0.019c1.53-0.036,3.05-0.144,4.52-0.327
                c0.058-0.006,0.116-0.015,0.175-0.023c1.514-0.221,3.037-0.61,4.13-0.906c3.05-0.857,5.574-1.887,7.713-3.152l3.409-2.013
                l2.02,3.405c5.766,9.724,16.867,16.803,28.955,18.474c1.964,0.276,3.953,0.415,5.909,0.415c9.82,0,19.171-3.481,26.333-9.802
                l3.04-2.682l2.616,3.096c7.284,8.618,18.178,13.783,29.893,14.173c0.551,0.017,1.098,0.004,1.647,0.001
                c-3.008,5.793-4.655,12.299-4.655,19.072c0,20.098,14.41,36.888,33.441,40.597c1.954,36.836,24.031,70.489,57.384,86.75
                l29.562,14.415l0.234,0.113c10.163,4.898,16.478,14.741,16.478,25.688c0,15.114,12.297,27.411,27.411,27.411h9.853
                c15.114,0,27.411-12.297,27.411-27.411c0-21.804-7.886-42.651-21.448-59.093c2.464,0.172,4.937,0.269,7.413,0.269
                c1.458,0,2.919-0.03,4.378-0.091c56.463-2.342,100.665-48.494,100.629-105.07c-0.002-4.464-3.622-8.079-8.084-8.079
                c-0.001,0-0.003,0-0.005,0c-4.465,0.002-8.082,3.625-8.079,8.09c0.03,47.871-37.364,86.924-85.131,88.905
                c-14.783,0.614-29.559-2.451-42.713-8.865l-29.562-14.415c-10.235-4.991-17.575-14.428-20.17-25.28
                c0.069-0.005,0.138-0.003,0.206-0.009c6.341-0.507,12.016-2.109,16.84-4.746c5.092-2.755,9.358-6.313,12.684-10.575l2.465-3.161
                l3.135,2.499c4.189,3.34,9.257,5.893,14.657,7.385c0.093,0.026,0.186,0.05,0.28,0.072c3.382,0.805,6.814,1.213,10.201,1.213
                c2.46,0,4.977-0.22,7.28-0.636c0.036-0.006,0.071-0.013,0.107-0.021c6.424-1.25,12.051-3.593,16.652-6.914
                c2.389-1.686,4.536-3.54,6.378-5.51c0.046-0.05,0.092-0.099,0.136-0.15c1.864-2.094,3.488-4.346,4.828-6.694l1.944-3.409
                l3.429,1.908c2.672,1.487,5.224,2.577,7.622,3.25c2.748,0.758,5.651,1.236,8.569,1.422l0.136,0.01
                c2.596,0.213,5.522,0.024,9.03-0.594c3.548-0.62,6.471-1.486,9.193-2.728l0.214-0.094c11.969-5.108,21.12-15.679,23.319-26.946
                l0.721-3.696l3.729,0.528c1.288,0.182,2.661,0.36,4.146,0.36c0.001,0,0.003,0,0.005,0c1.435-0.015,2.916-0.117,4.405-0.308
                c1.499-0.191,3.031-0.472,4.568-0.84c1.42-0.342,2.933-0.951,4.267-1.487c0.029-0.012,0.073-0.03,0.102-0.042
                c1.5-0.624,3.014-1.343,4.5-2.137c0.055-0.029,0.11-0.059,0.164-0.089c1.32-0.746,2.62-1.69,4.062-2.758
                c0.047-0.036,0.094-0.07,0.14-0.107c1.264-0.98,2.697-2.091,3.921-3.302c0.044-0.044,0.088-0.088,0.132-0.133
                c1.294-1.34,2.541-2.752,3.709-4.204c0.522-0.65,1.033-1.302,1.52-1.965c0.108-0.148,0.211-0.299,0.309-0.453
                c0.446-0.702,0.876-1.409,1.295-2.127c0.885-1.52,1.706-3.084,2.443-4.648c0.066-0.14,0.127-0.281,0.185-0.425
                c1.315-3.269,2.227-6.432,2.71-9.398c0.021-0.127,0.039-0.254,0.053-0.383c0.299-2.626,0.585-6.041,0.14-9.192
                c-0.475-3.331-1.19-6.078-2.184-8.398l-1.438-3.359l3.229-1.71c2.466-1.305,4.813-2.935,6.976-4.844
                c0.082-0.072,0.163-0.147,0.241-0.222c2.278-2.181,4.22-4.473,5.817-6.886c3.599-5.558,5.942-12.11,6.595-18.393l0.014-0.124
                C512.465,204.693,511.767,198.418,509.61,192.336z M299,396.014l29.563,14.415c0.011,0.005,0.023,0.011,0.034,0.016
                c26.682,12.859,43.924,40.093,43.924,69.383c0,6.199-5.043,11.242-11.242,11.242h-9.853c-6.199,0-11.242-5.043-11.242-11.242
                c0-17.213-9.82-32.636-25.661-40.27l-29.695-14.478c-27.857-13.581-46.375-41.568-48.284-72.288
                c0.635-0.128,1.27-0.259,1.898-0.416c4.329-1.093,6.951-5.489,5.859-9.817c-1.094-4.329-5.489-6.95-9.817-5.859
                c-2.011,0.509-4.097,0.765-6.198,0.765c-13.895,0-25.199-11.304-25.199-25.199c0-4.759,1.328-9.305,3.735-13.213
                c6.198,14.171,19.455,24.446,34.904,26.641l2.507,0.357l0.733,2.423c2.005,6.632,5.801,12.63,10.978,17.343
                c3.884,3.538,8.419,6.271,13.136,7.942C271.21,371.795,282.478,387.958,299,396.014z M495.64,209.173l-0.028,0.258
                c-0.4,3.851-1.852,7.876-4.043,11.26c-0.853,1.287-2.01,2.651-3.353,3.952c-1.174,1.02-2.425,1.885-3.721,2.571l-8.118,4.298
                c-0.232-0.021-0.466-0.036-0.703-0.036h-14.033c-9.365,0-16.982-7.617-16.982-16.982c0-18.279-14.872-33.151-33.151-33.151h-1.178
                c-18.279,0-33.151,14.871-33.151,33.151c0,8.301-6.753,15.054-15.054,15.054c-9.365,0-16.982-7.617-16.982-16.982v-7.071
                c0-4.465-3.62-8.084-8.084-8.084c-4.465,0-8.084,3.62-8.084,8.084v7.071c0,18.279,14.871,33.151,33.151,33.151
                c17.216,0,31.222-14.006,31.222-31.222c0-9.365,7.617-16.982,16.982-16.982h1.178c9.365,0,16.982,7.617,16.982,16.982
                c0,18.279,14.871,33.151,33.151,33.151h11.497l2.267,5.294c0.421,0.985,0.772,2.437,1.038,4.304
                c0.136,0.967,0.078,2.61-0.175,4.886c-0.29,1.685-0.84,3.555-1.635,5.565c-0.511,1.07-1.078,2.141-1.684,3.183
                c-0.265,0.454-0.538,0.905-0.819,1.354c-0.304,0.405-0.619,0.804-0.936,1.198c-0.836,1.04-1.732,2.057-2.661,3.023
                c-0.534,0.513-1.518,1.279-2.336,1.912c-0.665,0.494-1.701,1.258-2.259,1.586c-0.992,0.527-1.991,1-2.908,1.382
                c-0.585,0.235-1.675,0.674-2.006,0.765c-0.959,0.229-1.917,0.405-2.841,0.523c-0.868,0.111-1.718,0.17-2.457,0.179
                c-0.372,0-1.437-0.152-1.786-0.202l-11.328-1.606c-4.262-0.606-8.245,2.234-9.069,6.456l-2.191,11.228
                c-1.186,6.072-6.743,12.175-13.832,15.186c-0.034,0.015-0.07,0.03-0.104,0.045l-0.307,0.136c-0.033,0.015-0.068,0.03-0.1,0.045
                c-1.443,0.662-3.126,1.146-5.308,1.527c-1.662,0.292-3.007,0.435-4.11,0.435c-0.266,0-0.531-0.008-0.887-0.037l-0.29-0.021
                c-1.875-0.119-3.678-0.415-5.323-0.868c-0.787-0.221-2.136-0.713-4.091-1.8l-10.42-5.798c-3.874-2.156-8.758-0.79-10.954,3.059
                l-5.907,10.355c-0.766,1.343-1.706,2.649-2.796,3.883c-1.079,1.143-2.368,2.245-3.896,3.323c-2.745,1.98-6.209,3.391-10.294,4.193
                c-3.25,0.579-7.046,0.472-10.671-0.371c-3.26-0.919-6.28-2.439-8.742-4.402l-5.425-4.326c0.027-0.267,0.041-0.538,0.041-0.812
                v-1.178c0-18.279-14.872-33.151-33.151-33.151c-8.301,0-15.054-6.753-15.054-15.054c0-9.365,7.617-16.982,16.982-16.982h7.07
                c4.465,0,8.084-3.62,8.084-8.084s-3.62-8.084-8.084-8.084h-7.07c-18.279,0-33.151,14.871-33.151,33.151
                c0,17.216,14.006,31.222,31.222,31.222c9.365,0,16.982,7.617,16.982,16.982v0.86l-6.876,8.815
                c-1.945,2.493-4.512,4.612-7.66,6.316c-2.843,1.554-6.343,2.507-10.391,2.831c-2.021,0.158-4.149,0.009-6.362-0.439
                c-0.033-0.008-0.067-0.015-0.101-0.023c-1.2-0.248-2.423-0.578-3.67-1.004c-2.786-0.946-5.512-2.588-7.883-4.747
                c-3.019-2.75-5.229-6.231-6.389-10.069l-2.226-7.362c-0.911-3.012-3.486-5.221-6.601-5.664l-7.614-1.082
                c-10.855-1.544-20.042-9.309-23.405-19.784l-3.375-10.513c-1.093-3.405-4.25-5.614-7.693-5.614c-0.593,0-1.194,0.066-1.795,0.202
                l-10.771,2.446c-2.109,0.48-4.331,0.681-6.504,0.61c-7.15-0.238-13.739-3.319-18.077-8.452l-7.946-9.404
                c-1.403-1.661-3.416-2.686-5.585-2.845c-2.17-0.155-4.309,0.563-5.939,2.001l-9.232,8.148c-5.07,4.475-12.051,6.514-19.315,5.496
                c-7.232-0.999-14.014-5.202-17.279-10.707l-6.135-10.347c-2.274-3.836-7.225-5.104-11.065-2.837l-10.357,6.117
                c-0.904,0.534-2.204,1.041-3.787,1.486c-0.902,0.244-1.605,0.405-2.09,0.482c-0.906,0.109-1.854,0.175-2.885,0.198
                c-0.279,0.009-0.467,0.013-0.656,0.013c-3.341,0-7.116-0.985-10.93-2.849c-1.126-0.638-2.223-1.319-3.209-1.992l-0.627-0.433
                c-0.152-0.105-0.307-0.203-0.371-0.25c-0.46-0.387-0.99-0.853-1.468-1.292c-1.063-0.988-1.796-1.719-2.351-2.344
                c-0.747-0.942-1.44-1.902-2.051-2.84c-0.219-0.336-0.435-0.675-0.642-1.022c-0.194-0.391-0.378-0.785-0.556-1.184
                c-0.398-0.894-0.74-1.78-1.02-2.639c-0.35-1.341-0.528-2.825-0.514-4.304l0.095-9.787c0.038-3.825-2.612-7.153-6.348-7.974
                l-9.561-2.102c-1.273-0.279-2.581-0.764-3.889-1.442c-0.725-0.467-1.459-0.995-2.188-1.574c-0.372-0.295-0.742-0.603-1.109-0.922
                c-0.133-0.142-0.291-0.321-0.455-0.503l-0.269-0.297c-0.744-0.841-1.525-1.82-2.267-2.838c-0.508-0.792-1.104-1.963-1.65-3.049
                c-0.267-0.542-0.555-1.163-0.805-1.73c-0.06-0.185-0.136-0.431-0.216-0.689l-0.165-0.521c-0.41-1.347-0.766-2.725-1.063-4.1
                c-1.596-10.762,3.33-17.764,6.513-20.215l8.616-6.634c3.283-2.529,4.124-7.131,1.945-10.656l-5.717-9.248
                c-0.85-1.376-1.485-2.702-1.84-3.841c-0.534-1.996-0.834-3.82-0.871-5.243c-0.083-4.17,0.942-8.611,2.827-12.213
                c0.618-1.191,1.184-2.193,1.726-3.055c0.667-0.91,1.371-1.795,2.139-2.681l0.226-0.267c0.93-1.121,2.24-2.259,4.195-3.635
                l0.234-0.169c0.925-0.677,2.32-1.369,4.099-2.038c0.731-0.269,1.379-0.477,1.929-0.618c0.729-0.143,1.448-0.249,2.14-0.313
                l11.543-1.065c4.325-0.399,7.561-4.143,7.33-8.481L62.89,95.757c-0.148-2.782,0.527-5.92,1.953-9.083
                c0.929-1.833,1.926-3.44,2.965-4.78c1.565-1.845,2.979-3.263,4.32-4.328c2.07-1.537,3.824-2.635,5.154-3.238
                c1.989-0.88,3.796-1.501,5.374-1.846c1.686-0.313,3.346-0.471,4.869-0.472l0.465,0.004c0.88,0.024,2.344,0.168,4.403,0.71
                l11.265,2.967c6.521,6.629,6.494,17.325-0.094,23.913c-6.261,6.262-9.71,14.586-9.71,23.441c0,8.855,3.448,17.18,9.71,23.441
                l0.833,0.833c6.261,6.261,14.586,9.71,23.441,9.71s17.18-3.448,23.441-9.71c5.87-5.869,15.42-5.869,21.289,0
                c6.622,6.621,6.622,17.395,0,24.017l-4.999,4.999c-3.157,3.157-3.156,8.276,0,11.433c1.579,1.578,3.648,2.367,5.716,2.367
                c2.068,0,4.138-0.789,5.717-2.368l4.998-4.999c12.925-12.925,12.925-33.956,0-46.882c-12.174-12.174-31.981-12.174-44.155,0
                c-3.208,3.208-7.472,4.974-12.009,4.974c-4.536,0-8.8-1.767-12.008-4.974l-0.833-0.833c-3.208-3.208-4.974-7.472-4.974-12.009
                c0-4.536,1.767-8.8,4.974-12.008c12.822-12.823,12.917-33.615,0.302-46.565l1.62-5.814c0.755-2.708,2.336-5.449,4.701-8.15
                c1.25-1.31,2.665-2.54,4.207-3.659c1.483-0.999,3.261-1.943,5.285-2.807c3.07-1.221,6.249-1.841,9.45-1.841
                c3.905,0,7.61,0.919,10.712,2.657l10.442,5.848c3.876,2.173,8.778,0.807,10.976-3.054l5.922-10.401
                c3.33-5.85,9.612-10.062,16.805-11.269c1.497-0.252,3.012-0.379,4.502-0.379c5.821,0,11.188,1.88,15.113,5.292l6.38,5.548
                c-0.001,0.053-0.008,0.105-0.008,0.157v25.038c0,18.279,14.871,33.151,33.151,33.151c9.365,0,16.982,7.617,16.982,16.982v1.178
                c0,9.365-7.619,16.982-16.982,16.982c-17.216,0-31.222,14.006-31.222,31.222c0,18.279,14.871,33.151,33.151,33.151h7.07
                c4.465,0,8.084-3.62,8.084-8.084c0-4.465-3.62-8.084-8.084-8.084h-7.07c-9.365,0-16.982-7.619-16.982-16.982
                c0-8.301,6.753-15.054,15.054-15.054c18.279,0,33.151-14.872,33.151-33.151v-1.178c0-18.279-14.872-33.151-33.151-33.151
                c-9.365,0-16.982-7.617-16.982-16.982V36.603c0-0.229-0.015-0.454-0.034-0.678l6.104-7.247c0.906-1.076,1.97-2.062,3.197-2.957
                c1.357-1.001,2.84-1.885,4.409-2.629c3.434-1.429,6.796-2.155,9.995-2.155c0.255,0,1.001,0.016,1.009,0.016
                c0.045,0.001,0.089,0.001,0.134,0.001c3.409,0.013,6.959,0.849,10.555,2.488c1.547,0.798,2.987,1.719,4.314,2.767
                c1.205,0.94,2.221,1.943,3.106,3.069l7.57,9.616c1.34,1.702,3.305,2.795,5.457,3.034c2.151,0.239,4.31-0.395,5.992-1.76
                l9.499-7.717c5.035-4.091,12.413-5.72,19.793-4.206c7.148,1.444,13.268,5.88,16.371,11.864l5.524,10.656
                c2.049,3.953,6.906,5.505,10.87,3.471l10.679-5.482c2.821-1.449,6.161-2.214,9.663-2.214c3.608,0,7.181,0.793,10.622,2.361
                c6.743,3.068,12.008,9.038,13.411,15.211l0.971,4.272c-5.664,2.505-10.54,6.631-14.113,12.057
                c-4.897,7.439-6.72,16.324-5.132,25.018c0.722,3.956-0.139,7.956-2.425,11.264c-2.286,3.308-5.724,5.527-9.68,6.25
                c-4.462,0.815-8.976-0.157-12.706-2.737c-3.732-2.579-6.236-6.457-7.051-10.919l-1.27-6.956c-0.802-4.393-5.014-7.298-9.405-6.501
                c-4.392,0.802-7.303,5.012-6.501,9.405l1.27,6.956c1.59,8.71,6.477,16.281,13.761,21.315c5.604,3.874,12.109,5.89,18.779,5.89
                c2,0,4.015-0.181,6.024-0.549c8.204-1.498,15.334-6.101,20.076-12.962s6.529-15.157,5.031-23.361
                c-0.825-4.514,0.171-9.335,2.731-13.224c2.561-3.89,6.365-6.469,10.711-7.264c0.11-0.02,0.216-0.052,0.323-0.077
                c0.28-0.031,0.562-0.075,0.844-0.136l11.66-2.529c0.771-0.167,1.577-0.294,2.305-0.371c0.042-0.003,0.429-0.038,1.184-0.038
                c0.264,0,0.528,0.004,0.794,0.012c2.074,0.095,3.62,0.297,4.725,0.62c2.245,0.653,3.932,1.301,5.297,2.034
                c1.493,0.862,3.606,2.148,5.165,3.579c3.152,2.83,5.554,6.181,6.953,9.696c0.796,2.168,1.207,3.639,1.342,4.828
                c0.219,1.803,0.243,3.423,0.073,4.955l-1.222,11.017c-0.464,4.177,2.351,8.013,6.475,8.825l10.879,2.139
                c1.468,0.288,3.01,0.799,4.654,1.55c1.009,0.45,2.336,1.35,4.354,2.957c0.348,0.274,0.787,0.709,1.411,1.406
                c0.842,0.93,1.617,1.871,2.41,2.934l0.106,0.137c0.208,0.299,0.743,1.263,1.099,1.906l0.194,0.349
                c0.537,1.02,1.124,2.16,1.417,2.923c1.623,4.367,2.206,8.819,1.635,12.594c-0.167,1.153-0.374,2.21-0.637,3.25
                c-0.105,0.375-0.526,1.389-0.722,1.859c-0.701,1.572-1.293,2.63-1.812,3.234l-7.3,8.505c-2.76,3.215-2.569,8.016,0.438,11.002
                l7.956,7.901c0.517,0.513,1.03,1.093,1.516,1.714c0.234,0.3,0.465,0.61,0.689,0.93l1.094,1.645c0.977,1.562,1.7,3.007,2.193,4.373
                C495.687,201.451,496.111,205.257,495.64,209.173z"
                    />
                  </g>
                </svg>
                <span>Neurology</span>
              </span>
            </li> */}
          </ul>
          <div className="st-height-b25 st-height-lg-b25" />
          <div className="tab-content">
            <div
              id="Exams"
              className={`st-tab ${isActive === 0 ? "active" : ""}`}
            >
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/pulmonary.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            Past <span>Board Exams</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Looking to conquer your Medical Laboratory
                            Technologist (SMLTSA) board exams? Skilled Lab is
                            here to help!{" "}
                          </h4>
                          <div className="st-imagebox-text">
                            We understand the importance of effective
                            preparation, which is why we're providing access to
                            past South African Medical Laboratory Technology
                            Society (SMLTSA) board exams. Utilize these practice
                            exams to familiarize yourself with the format, test
                            your knowledge, and boost your confidence on exam
                            day.
                          </div>
                        </div>
                        <div className="st-imagebox-btn">
                          <ScrollLink
                            to="newsletter"
                            spy={true}
                            duration={500}
                            className="st-btn st-style1 st-size-medium st-color1"
                          >
                            Learn More
                          </ScrollLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="X-ray"
              className={`st-tab ${isActive === 1 ? "active" : ""}`}
            >
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/xray.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            Practical <span>Study Guides</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Bridge the Gap to Real-World Success
                          </h4>
                          <div className="st-imagebox-text">
                            Forget dry textbooks! Skilled Lab's study guides are
                            all about practical application. We understand that
                            real-world labs function differently than
                            classrooms. That's why our guides are designed to
                            bridge the gap, offering focused content that
                            reflects the day-to-day tasks and procedures you'll
                            encounter as a Medical Laboratory Technologist.
                            These practical resources go beyond memorization,
                            equipping you with the knowledge and problem-solving
                            skills needed to thrive in a busy lab environment
                            and ultimately ace your board exams.
                          </div>
                        </div>
                        <div className="st-imagebox-btn">
                          <ScrollLink
                            to="newsletter"
                            spy={true}
                            duration={500}
                            className="st-btn st-style1 st-size-medium st-color1"
                          >
                            Read More
                          </ScrollLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="Pulmonary"
              className={`st-tab ${isActive === 2 ? "active" : ""}`}
            >
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/pulmonary.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            Group/Individual <span>Tutoring</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Tutoring: Personalized Support for Your Success
                          </h4>
                          <div className="st-imagebox-text">
                            Skilled Lab goes beyond self-study materials. We
                            recognize that everyone learns differently. That's
                            why we offer both individual and group tutoring
                            sessions to cater to your specific needs and
                            learning style. Need one-on-one clarification on a
                            complex topic? Our individual sessions provide
                            focused attention and in-depth support. Prefer a
                            collaborative environment? Our group sessions foster
                            a dynamic learning experience where you can share
                            insights and ask questions alongside your peers. No
                            matter your preference, Skilled Lab's expert tutors
                            are here to guide you towards mastery in Medical
                            Laboratory Technology.
                          </div>
                        </div>
                        <div className="st-imagebox-btn">
                          <ScrollLink
                            to="newsletter"
                            spy={true}
                            duration={500}
                            className="st-btn st-style1 st-size-medium st-color1"
                          >
                            Read More
                          </ScrollLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="Cardiology"
              className={`st-tab ${isActive === 3 ? "active" : ""}`}
            >
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/xray.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            Coming soon <span>Short Courses</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Short Courses: Master In-Demand Skills
                          </h4>
                          <div className="st-imagebox-text">
                            The world of clinical laboratories is constantly
                            evolving. Skilled Lab is committed to keeping you at
                            the forefront of your field. We're currently
                            developing a series of short courses focused on
                            Laboratory Information Management Systems (LIMS). In
                            a nutshell, LIMS are like the brains of a modern
                            lab! These software systems manage everything from
                            sample tracking and test results to data analysis
                            and reporting. Our comprehensive programs will equip
                            you with the knowledge and skills to confidently use
                            these powerful tools, giving you a valuable edge in
                            today's job market. Stay tuned for exciting
                            announcements about upcoming LIMS course launches!
                            In the meantime, explore our other resources to gain
                            a competitive edge
                          </div>
                        </div>
                        <div className="st-imagebox-btn">
                          <ScrollLink
                            to="newsletter"
                            spy={true}
                            duration={500}
                            className="st-btn st-style1 st-size-medium st-color1"
                          >
                            Read More
                          </ScrollLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              id="DentalCare"
              className={`st-tab ${isActive === 4 ? "active" : ""}`}
            >
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/dental-care.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            Welcome to our <span>DentalCare</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Magni nemo libero debitis vitae sapiente quos.{" "}
                          </h4>
                          <div className="st-imagebox-text">
                            Oillum abrem deleniti adipisci suscipit dignissimos.
                            remaining essentially unchanged. It was popularised
                            in the with the . Lorem Ipsum is simply dummy text
                            of the printing and typesetting industry. Lorem
                            ipsum dolor sit amet consectetur adipisicing elit.
                          </div>
                        </div>
                        <div className="st-imagebox-btn">
                          <Link
                            to=""
                            className="st-btn st-style1 st-size-medium st-color1"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div
              id="Neurology"
              className={`st-tab ${isActive === 5 ? "active" : ""}`}
            >
              <div className="st-imagebox st-style2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="st-imagebox-img">
                      <img src="/images/neurology.png" alt="service" />
                    </div>
                    <div className="st-height-b0 st-height-lg-b30" />
                  </div>
                  <div className="col-lg-6">
                    <div className="st-vertical-middle">
                      <div className="st-vertical-middle-in">
                        <div className="st-imagebox-info">
                          <h2 className="st-imagebox-title">
                            Welcome to our <span>Neurology</span>
                          </h2>
                          <h4 className="st-imagebox-subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Magni nemo libero debitis vitae sapiente quos.{" "}
                          </h4>
                          <div className="st-imagebox-text">
                            Oillum abrem deleniti adipisci suscipit dignissimos.
                            remaining essentially unchanged. It was popularised
                            in the with the . Lorem Ipsum is simply dummy text
                            of the printing and typesetting industry. Lorem
                            ipsum dolor sit amet consectetur adipisicing elit.
                          </div>
                        </div>
                        <div className="st-imagebox-btn">
                          <Link
                            to=""
                            className="st-btn st-style1 st-size-medium st-color1"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default Resources;
