import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const Post = ({
  image,
  title,
  description,
  author,
  date,
  articleId,
  authorLink,
}) => {
  return (
    <div className="col-lg-4">
      <div className="st-post st-style3">
        <Link
          to={`/article/${articleId}`}
          className="st-post-thumb st-link-hover-wrap st-zoom"
        >
          <img
            className="st-zoom-in st-article-image"
            src={image}
            alt={image}
          />
          <span className="st-link-hover">
            <i>
              <Icon icon="fa6-solid:link" />
            </i>
          </span>
        </Link>
        <div className="st-post-info">
          <h2 className="st-post-title">
            <Link to={`/article/${articleId}`}>{title}</Link>
          </h2>
          <div className="st-post-meta">
            <span className="st-post-date">{date}</span>
            <span>
              Posted by:
              <Link to={authorLink} className="st-post-avatar">
                <span className="st-post-avatar-text">{author}</span>
              </Link>
            </span>
          </div>
          <div className="st-post-text-cover">
            <p className="st-post-text">{description}</p>
          </div>
        </div>
        <div className="st-post-footer">
          <Link
            to={`/article/${articleId}`}
            className="st-btn st-style2 st-color1 st-size-medium"
          >
            Read More
          </Link>
        </div>
      </div>
      <div className="st-height-b0 st-height-lg-b30" />
    </div>
  );
};

export default Post;
