import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Post from "./Post";

const PostWrapper = ({ data }) => {
  const post = data.slice(0, 3);
  return (
    <section id="blog">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading
        title="Latest News"
        subTitle="Whether you're new to the field or a seasoned professional, our blog keeps you informed on the latest trends,<br /> lab hacks, and career tips in the dynamic world of clinical laboratories."
      />
      <div className="container">
        <div className="row">
          {post !== undefined &&
            post?.map((element, index) => (
              <Post {...element} key={`0${index}`} />
            ))}
        </div>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default PostWrapper;
