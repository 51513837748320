import React from "react";
import Lottie from "react-lottie";
import animationData from "./skilledLabPreloader";

const Preloader = ({ varient }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={`st-perloader ${varient ? varient : ""}`}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default Preloader;
