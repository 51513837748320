import { Icon } from "@iconify/react";
import clsx from "clsx";
import qs from "qs";
import { React, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useQuery } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { RingLoader } from "react-spinners";
import { Seo } from "../Components/MetaTags/Seo";

const Article = () => {
  const { articleId } = useParams();
  const [id, setId] = useState(articleId);
  const [totalArticles, setTotalArticles] = useState();

  const location = useLocation();
  const env =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "http://skilledlab.co.za";
  const url = env + location.pathname;

  const getArticle = async (id) => {
    const query = qs.stringify({
      populate: {
        image: {
          fields: ["url"],
        },
        categories: {
          populate: true,
        },
      },
      filters: {
        articleId: {
          $eq: id,
        },
      },
    });

    return await fetch(
      process.env.REACT_APP_CMS_API + "/api/articles?" + query
    ).then((res) => res.json());
  };

  const { status, isLoading, data } = useQuery({
    queryKey: ["article", id],
    queryFn: async () => getArticle(id),
    select: ({ data }) => {
      if (!data) return [];

      return data.map(({ id: articleIndex, attributes }) => ({
        page: articleIndex,
        articleId: attributes.articleId,
        title: attributes.title,
        description: attributes.slug,
        image: attributes.image?.data?.attributes?.url,
        date: attributes.date,
        author: attributes.author,
        content: attributes.content,
        categories: attributes.categories.data.map((tag) => tag.attributes.tag),
      }));
    },
  });

  const getAllArticles = async () => {
    const query = qs.stringify({
      populate: {
        image: {
          fields: ["url"],
        },
        categories: {
          populate: true,
        },
      },
      sort: "date:desc",
      pagination: {
        pageSize: 25,
        page: 1,
      },
    });

    return await fetch(
      process.env.REACT_APP_CMS_API + "/api/articles?" + query
    ).then((res) => res.json());
  };

  const { status: articlesStatus, data: recommendedArticles } = useQuery({
    queryKey: ["articles"],
    queryFn: async () => getAllArticles(),
    select: ({ data }) => {
      if (!data) return [];

      return data.map(({ id: articleIndex, attributes }) => ({
        page: articleIndex,
        articleId: attributes.articleId,
        title: attributes.title,
        image: attributes.image?.data?.attributes?.url,
        date: attributes.date,
      }));
    },
  });

  const [currPage, setCurrPage] = useState(1);

  // useEffect(() => {
  //   if (data) {
  //     setCurrPage(data[0]?.page);
  //   }
  // }, [data, currPage]);

  useEffect(() => {
    if (recommendedArticles) setTotalArticles(recommendedArticles.length);
  }, [recommendedArticles]);

  const next = () => {
    if (currPage < totalArticles || currPage !== totalArticles) {
      setCurrPage((curr) => (curr += 1));
      console.log("next: ", currPage);
      const nextArticleId = recommendedArticles.filter(
        ({ page }) => page === currPage
      );
      setId(nextArticleId[0]?.articleId);
      console.log(id);
    }
  };

  const previous = () => {
    if (currPage > 1) {
      setCurrPage((curr) => (curr -= 1));
      const nextArticleId = recommendedArticles.filter(
        ({ page }) => page === currPage
      );
      setId(nextArticleId[0]?.articleId);
      console.log(id);
    }
  };

  return status !== "success" && isLoading ? (
    <div className="st-ring-loader ">
      <RingLoader color="#36d7b7" size={400} />
    </div>
  ) : (
    <div className="st-content" id="top">
      <div
        className="st-page-heading st-dynamic-bg"
        style={{
          backgroundImage: `url(${data[0]?.image})`,
          objectFit: "cover",
        }}
      >
        <div className="container">
          <div className="st-page-heading-in text-center">
            <h4>
              <Link to="/" className="st-back-to-home">
                <Icon icon="ph:arrow-left-bold" />
                Back To Home
              </Link>
            </h4>
            <h1 className="st-page-heading-title">{data[0]?.title}</h1>
            <div className="st-post-label">
              <span>
                By <Link>{data[0]?.author}</Link>
              </span>
              <span>{data[0]?.date}</span>
            </div>
          </div>
        </div>
      </div>
      {/* .st-page-heading */}
      <div className="st-height-b100 st-height-lg-b80" />
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="st-post-details st-style1">
              <div className="st-post-info">
                <div className="st-post-text">
                  {data[0]?.content.map(({ children }) => (
                    <p>
                      {children?.map(({ text, bold }, index) => (
                        <span
                          className={clsx({
                            "st-post-text-bold": bold === true,
                          })}
                          key={`00${index}`}
                        >
                          {text}
                        </span>
                      ))}
                    </p>
                  ))}
                </div>
              </div>
              <div className="st-height-b35 st-height-lg-b35" />
              <div className="st-post-meta">
                <div className="st-post-tages">
                  <h4 className="st-post-tage-title">Tags:</h4>
                  <ul className="st-post-tage-list st-mp0">
                    {data[0]?.categories.map((tag) => (
                      <li key={tag}>
                        <Link to="">{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="st-post-share">
                  <h4 className="st-post-share-title">Share:</h4>
                  <div className="st-post-share-btn-list">
                    <EmailShareButton url={url}>
                      <EmailIcon size={24} round={true} />
                    </EmailShareButton>
                    <FacebookShareButton url={url}>
                      <FacebookIcon size={24} round={true} />
                    </FacebookShareButton>
                    <LinkedinShareButton url={url}>
                      <LinkedinIcon size={24} round={true} />
                    </LinkedinShareButton>
                    <TelegramShareButton url={url}>
                      <TelegramIcon size={24} round={true} />
                    </TelegramShareButton>
                    <TwitterShareButton url={url}>
                      <TwitterIcon size={24} round={true} />
                    </TwitterShareButton>
                    <ViberShareButton url={url}>
                      <ViberIcon size={24} round={true} />
                    </ViberShareButton>
                    <WhatsappShareButton url={url}>
                      <WhatsappIcon size={24} round={true} />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
              <div className="st-height-b60 st-height-lg-b60" />
              <div className="st-post-btn-group">
                {currPage > 1 && (
                  <ScrollLink to="top" spy={true} duration={500}>
                    <Link
                      to={`/article/${id}`}
                      className="st-btn st-style2 st-color1 st-size-medium"
                      onClick={previous}
                    >
                      Previous Post
                    </Link>
                  </ScrollLink>
                )}

                {(currPage < totalArticles || currPage !== totalArticles) && (
                  <ScrollLink to="top" spy={true} duration={500}>
                    <Link
                      to={`/article/${id}`}
                      className="st-btn st-style2 st-color1 st-size-medium"
                      onClick={next}
                    >
                      Next Post
                    </Link>
                  </ScrollLink>
                )}
              </div>
              <div className="st-height-b60 st-height-lg-b60" />
              {/* <div className="comments-area">
                <div className="comment-list-outer">
                  <h2 className="comments-title">Comments(3)</h2>
                  <ol className="comment-list">
                    <li className="comment">
                      <div className="comment-body">
                        <div className="comment-meta">
                          <div className="comment-author">
                            <img
                              src="/images/comment1.jpg"
                              alt="comment1"
                              className="avatar"
                            />
                            <span className="nm">
                              <Link to="">Hannibal Lecter</Link>
                            </span>
                          </div>
                          <div className="comment-metadata">
                            <Link to="">
                              <span>26 Jan, 2016</span>
                            </Link>
                          </div>
                        </div>
                        =
                        <div className="comment-content">
                          <p>
                            Lorem ipsum dolor sit amet. Lorem ipsum adipiscing
                            elit.
                          </p>
                        </div>
                        <div className="reply">
                          <Link to="" className="comment-reply-link">
                            <Icon icon="fa6-solid:reply" />
                            <span>Reply</span>
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="comment-respond">
                  <h2 className="comment-reply-title">Add your comment</h2>
                  <form method="post" className="comment-form">
                    <p className="comment-form-author">
                      <input
                        name="author"
                        type="text"
                        placeholder="Name*"
                        required=""
                      />
                    </p>
                    <p className="comment-form-email">
                      <input
                        name="email"
                        type="email"
                        placeholder="E-mail*"
                        required=""
                      />
                    </p>
                    <p className="comment-form-url">
                      <input
                        id="url"
                        name="url"
                        type="url"
                        placeholder="Website"
                      />
                    </p>
                    <p className="comment-form-comment">
                      <textarea
                        name="comment"
                        cols={40}
                        rows={5}
                        placeholder="Write here...*"
                        required=""
                      />
                    </p>
                    <p className="form-submit">
                      <button
                        type="submit"
                        className="st-btn st-style1 st-color1 st-size-medium"
                      >
                        Send Message
                      </button>
                    </p>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="st-height-b0 st-height-lg-b40" />
            {/* <div className="st-widget st-sidebar-widget">
              <h3 className="st-widget-title">Categories</h3>
              <ul className="st-widget-list">
                <li>
                  <Link>Audio</Link>
                </li>
                <li>
                  <Link>Video</Link>
                </li>
                <li>
                  <Link>Gallery</Link>
                </li>
                <li>
                  <Link>Doctors</Link>
                </li>
                <li>
                  <Link>Patients</Link>
                </li>
              </ul>
            </div> */}
            <div className="st-height-b30 st-height-lg-b30" />
            {/* <div className="st-widget st-sidebar-widget">
              <h3 className="st-widget-title">Archives</h3>
              <ul className="st-widget-list">
                <li>
                  <Link>March 2020</Link>
                </li>
                <li>
                  <Link>May 2020</Link>
                </li>
                <li>
                  <Link>June 2020</Link>
                </li>
                <li>
                  <Link>August 2020</Link>
                </li>
                <li>
                  <Link>September 2020</Link>
                </li>
                <li>
                  <Link>October 2020</Link>
                </li>
              </ul>
            </div> */}
            <div className="st-height-b30 st-height-lg-b30" />
            <div className="st-widget st-sidebar-widget">
              <h3 className="st-widget-title">Recent Post</h3>
              <ul className="st-post-widget-list st-mp0">
                {articlesStatus === "success" &&
                  recommendedArticles.slice(0, 4)?.map(
                    ({ articleId: recommendedArticleId, title, image, date }) =>
                      articleId &&
                      recommendedArticleId !== articleId && (
                        <li>
                          <div
                            key={recommendedArticleId}
                            className="st-post st-style1"
                          >
                            <Link
                              to={`/article/${recommendedArticleId}`}
                              onClick={() => setId(recommendedArticleId)}
                              className="st-post-thumb st-zoom"
                            >
                              <img
                                src={image || <Skeleton />}
                                alt="post1"
                                className="st-zoom-in img-thumb"
                              />
                            </Link>
                            <div className="st-post-info">
                              <h2 className="st-post-title">
                                <Link
                                  to={`/article/${recommendedArticleId}`}
                                  onClick={() => setId(recommendedArticleId)}
                                  href="blog-details-right-sidebar.html"
                                >
                                  {title || <Skeleton />}
                                </Link>
                              </h2>
                              <div className="st-post-date">
                                {date || <Skeleton />}
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                  )}
              </ul>
            </div>
            <div className="st-height-b30 st-height-lg-b30" />
            {/* <div className="st-widget st-sidebar-widget">
              <h3 className="st-widget-title">Popular Tags</h3>
              <div className="st-tagcloud">
                <Link to="" className="st-tag">
                  Gallery
                </Link>
                <Link to="" className="st-tag">
                  Quote
                </Link>
                <Link to="" className="st-tag">
                  Video
                </Link>
                <Link to="" className="st-tag">
                  Quote
                </Link>
                <Link to="" className="st-tag">
                  Audio
                </Link>
                <Link to="" className="st-tag">
                  Doctor
                </Link>
                <Link to="" className="st-tag">
                  Link
                </Link>
                <Link to="" className="st-tag">
                  Ipsum
                </Link>
                <Link to="" className="st-tag">
                  Enviroment
                </Link>
                <Link to="" className="st-tag">
                  Corporate
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="st-height-b100 st-height-lg-b80" />
      <Seo
        title={data[0]?.title}
        description={data[0]?.description}
        type={data[0]?.image}
        name={data[0]?.author}
        image={data[0]?.image}
      />
    </div>
  );
};
export default Article;
