import qs from "qs";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import About from "../Components/About/About";
import Accordion from "../Components/Accordion/Accordion";
import Contact from "../Components/Contact/Contact";
import Resources from "../Components/Department/Resources";
import Funfact from "../Components/Funfact/Funfact";
import Hero from "../Components/Hero/Hero";
import Iconbox from "../Components/Iconbox/Iconbox";
import Newsletter from "../Components/Newsletter/Newsletter";
import PostWrapper from "../Components/Post/PostWrapper";

const heroData = {
  bgImg: "images/hero-bg.jpg",
  bgShape: "shape/hero-shape.png",
  sliderImages: [
    {
      img: "images/hero-img2.jpeg",
    },
    {
      img: "images/hero-img4.jpeg",
    },
    {
      img: "images/hero-img2.png",
    },
    {
      img: "images/hero-img5.jpeg",
    },
  ],
  title: ["Med Techs", "Lab Techs", "Doctors", "Lab Assistants"],
};

const iconboxData = [
  {
    bg: "purple",
    icon: "icons/virtualClass.png",
    title: "Lab Tutoring",
    subTitle:
      "We offer personalized group and individual sessions bridging the gap between your education and practical skills employers seek.",
  },
  {
    bg: "green",
    icon: "icons/studying.png",
    title: "Study",
    subTitle:
      "Skilled Lab provides everything you need: past papers, in-depth study guides, and practical lab training. Everything you need in one place",
  },
  {
    bg: "red",
    icon: "icons/certificate.png",
    title: "Short courses",
    subTitle:
      "Coming soon: master in-demand lab systems with our upcoming short courses. For career changers and experienced professionals. Enroll Soon!",
  },
];

const aboutData = {
  title: "Experience matters: Learn from the best in the industry.",
  subTitle:
    "Skilled Lab is your one-stop shop for launching a successful career in the dynamic world of clinical laboratories. We understand the importance of real-world skills and practical knowledge in today's job market. That's why we offer a comprehensive range of short courses, certificates, and study resources designed to bridge the gap between theory and practice.",
  paragraph:
    "Our curriculum goes beyond traditional textbooks. We equip you with the hands-on training and knowledge you need to confidently navigate lab environments. Additionally, we provide access to valuable resources like past exam papers to help you ace your board exams and land your dream job. Whether you aspire to become a lab technician, medical technologist, or a skilled administrative assistant, Skilled Lab empowers you to achieve your goals and become a valuable asset in the healthcare field.",
  timeTable: [
    {
      day: "Monday",
      startTime: "9:00",
      endTime: "4:00",
    },
    {
      day: "Tuesday",
      startTime: "9:00",
      endTime: "4:00",
    },
    {
      day: "Wednesday",
      startTime: "9:00",
      endTime: "4:00",
    },
    {
      day: "Thursday",
      startTime: "9:00",
      endTime: "4:00",
    },
    {
      day: "Friday",
      startTime: "9:00",
      endTime: "4:00",
    },
    {
      day: "Saturday",
      startTime: "10:00",
      endTime: "1:00",
    },
  ],
  contact: "(+01) - 234 567 890",
};

const testimonialData = [
  {
    img: "images/avatar2.png",
    name: "Ralph Jones",
    designation: "Executive",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar3.png",
    name: "Francis Jara",
    designation: "Biographer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar4.png",
    name: "David Baer",
    designation: "UX Designer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar2.png",
    name: "Ralph Jones",
    designation: "Executive",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar3.png",
    name: "Francis Jara",
    designation: "Biographer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar4.png",
    name: "David Baer",
    designation: "UX Designer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
];

const priceData = [
  {
    title: "Blood Test",
    price: "39",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "0",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Hemoglobin Test",
    price: "89",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Homocysteine Test",
    price: "150",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "1",
      },
    ],
  },
  {
    title: "Blood Test",
    price: "39",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "0",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Hemoglobin Test",
    price: "89",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "0",
      },
    ],
  },
  {
    title: "Homocysteine Test",
    price: "150",
    featureList: [
      {
        title: "First Description",
        status: "1",
      },
      {
        title: "Second Description",
        status: "1",
      },
      {
        title: "Third Description",
        status: "1",
      },
      {
        title: "Fourth Description",
        status: "1",
      },
      {
        title: "Fifth Description",
        status: "1",
      },
    ],
  },
];

const faqData = {
  title: "Frequently Asked Questions",
  img: "images/faq-img.png",
  bgImg: "shape/faq-bg.svg",
  faqItems: [
    {
      title: "What is Skilled Lab?",
      content: `
      Whether you're fresh out of school or looking for a career change, Skilled Lab can help! We're like a one-stop shop for launching a successful career in clinical labs. We understand the gap between what you learn in textbooks and what employers actually need. That's why we're offering a bunch of resources, all in one place. We can set you up with personalized training, either on your own or in a group setting. We've got in-depth study guides to fill in any knowledge gaps. Plus, we have past exams so you can feel confident when it's time to get certified. With Skilled Lab, You can bridge the gap and become a valuable asset in the healthcare field.
      `,
    },
    {
      title: "Who is Skilled Lab for?",
      content: `Skilled Lab isn't just for one type of learner. Our programs are designed to benefit people at all stages of their clinical laboratory careers. Whether you're a university student seeking to bridge the theory-to-practice gap, a recent graduate needing to solidify your knowledge with practical skills, or an experienced professional looking to stay competitive by enhancing your skillset, Skilled Lab has something to offer. Even career changers can gain the necessary foundation to enter this exciting field.`,
    },
    {
      title: "How does Skilled Lab help me prepare for my board exams",
      content: `
      Skilled Lab gives you a double advantage when it comes to certification exams.  First, we provide access to practice questions from past exams. This lets you familiarize yourself with the format and types of questions you'll encounter. Second, you can leverage our group or individual tutoring sessions for targeted support on specific topics or areas you find challenging.  By combining practice with personalized instruction, Skilled Lab equips you to confidently tackle your certification exam
      `,
    },
    {
      title:
        "What kind of courses does Skilled Lab offer and when do the courses start?",
      content: `
      Skilled Lab doesn't offer traditional courses with fixed start dates. Instead, we focus on providing on-demand resources to get you career-ready in clinical labs. 
      Currently, we're developing in-depth courses on Laboratory Information Management Systems (LIMS) that will equip you with the skills employers are looking for.  
      we'll announce the launch date on our website and social media channels, so stay tuned! In the meantime, take advantage of our other resources like personalized training, study guides, and practice exams to get a head start.
      `,
    },
    {
      title: "Do you offer any free resources?",
      content: `
      We're currently building out our free resource library, but we do offer a taste of what's to come! 
      You can access a limited selection of past exam papers for viewing (though downloading isn't available yet) and informative articles to expand your knowledge base. 
      Stay tuned, as we're actively working on adding more free resources in the future!
      `,
    },
  ],
};

const newsletterData = {
  bgImg: "images/news-letter-bg.png",
  contact: "(+01) - 234 567 890",
};

// const brandData = [
//   {
//     bg: "orange",
//     img: "images/client1.png",
//   },
//   {
//     bg: "blue",
//     img: "images/client2.png",
//   },
//   {
//     bg: "red",
//     img: "images/client3.png",
//   },
//   {
//     bg: "green",
//     img: "images/client4.png",
//   },
//   {
//     bg: "dip-blue",
//     img: "images/client5.png",
//   },
//   {
//     bg: "orange",
//     img: "images/client1.png",
//   },
//   {
//     bg: "blue",
//     img: "images/client2.png",
//   },
//   {
//     bg: "red",
//     img: "images/client3.png",
//   },
//   {
//     bg: "green",
//     img: "images/client4.png",
//   },
//   {
//     bg: "dip-blue",
//     img: "images/client5.png",
//   },
// ];

const Home = () => {
  const getArticles = async () => {
    const query = qs.stringify({
      populate: {
        image: {
          fields: ["url"],
        },
        categories: {
          populate: true,
        },
      },
      sort: "date:desc",
      pagination: {
        pageSize: 10,
        page: 1,
      },
    });

    return await fetch(
      process.env.REACT_APP_CMS_API + "/api/articles?" + query
    ).then((res) => res.json());
  };

  const { data: articles, status } = useQuery({
    queryKey: ["articles"],
    queryFn: async () => getArticles(),
    select: ({ data }) => {
      if (!data) return [];

      return data.map(({ attributes }) => ({
        articleId: attributes.articleId,
        title: attributes.title,
        description: attributes.slug,
        image: attributes.image?.data?.attributes?.url,
        date: attributes.date,
        author: attributes.author,
      }));
    },
  });

  return (
    <>
      <Hero data={heroData} />
      <Iconbox data={iconboxData} />
      <About data={aboutData} />
      <Resources />
      {/* <SpecialistsSlider data={specialistData} /> */}
      <hr />
      {/* <TestimonialSlider data={testimonialData} /> */}
      <Funfact />
      {/* <PriceSlider data={priceData} /> */}
      <Accordion data={faqData} />
      <Newsletter data={newsletterData} />
      {articles &&
        articles.length > 0 &&
        ((status === "success" && <PostWrapper data={articles} />) || (
          <Skeleton count={5} />
        ))}
      {/* <BrandSlider data={brandData} /> */}
      <Contact />
    </>
  );
};

export default Home;
